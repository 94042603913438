.app {
  background: #f7f7f7;

  height: 100vh;
  width: 100vw;

  font-size: 18px;

  main {
    padding-top: 80px;
    padding-bottom: 32px;
  }

  .page-with-fab {
    padding-bottom: 54px;
  }

  .MuiFab-root {
    position: fixed;
    right: 16px;
    bottom: 16px;
  }
}
