.leaderboard {
  .placement {
    padding: 8px;

    .label {
      display: inline-block;
      background: lightgrey;
      border-radius: 8px;
      margin-right: 8px;

      .place, .score {
        display: inline-block;
        padding: 4px 8px;
        text-align: center;
        width: 50px;
      }

      .place {
        background: lightblue;
        border-radius: 8px;
        color: black;

        &.place-1 {
          background: goldenrod;
          color: white;
        }

        &.place-2 {
          background: darkgrey;
          color: white;
        }

        &.place-3 {
          background: saddlebrown;
          color: white;
        }
      }
    }

    .players {
      display: inline-block;
      margin-top: 8px;
    }

  }

  @media (min-width: 600px) {
    .placement {
      text-align: center;

      .players {
        display: block;
      }
    }
  }
}
