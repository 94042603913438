.players-list {
  .player {
    display: flex;
    align-items: center;
    padding: 4px;

    .name {
      flex-grow: 1;
    }
  }
}
