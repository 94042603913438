.player-score-card {
  padding: 8px;

  .player-name {
    font-size: 20px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    margin: 4px 4px 0;
    padding: 4px 8px;
    border-radius: 8px;

    &.dealer {
      background: lightblue;
    }
  }
}

.score-controls {
  display: flex;
  align-items: flex-end;

  .score-area {
    flex-grow: 1;
    text-align: center;

    .label {
      font-size: 12px;
    }

    .delta {
      display: inline-block;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 8px;
      background: lightblue;
    }
  }

  .points-btn {
    width: 50px;
  }
}
